<template>
    <el-dialog title="Mutare statie" :visible.sync="showPopup" width="100" :close-on-click-modal="false">
        <el-form label-position="top" :inline="false"  label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                    
                <el-col :md='24'>
                    <el-form-item label='Locatie' >
                        <el-select filterable v-model='selectedObject.IdLocatie'  class='full-width'>   
                            <el-option-group v-for="client in Items" :key="'grup-' + client.Id" :label="client.Nume">
                                    <el-option
                                        v-for="locatie in client.Locatii"
                                        :key="'locatie-' + locatie.Id"
                                        :label="locatie.Nume"
                                        :value="locatie.Id">
                                    </el-option>
                                </el-option-group>
                        </el-select>
                    </el-form-item>
                </el-col>
        
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from '@/pages/BasePage';

export default {
    name: "MutareStatie_dialog",
    extends: BasePage,
    data () {
        return {
            baseUrl :'',
            showPopup: false,
            Items:[],
            selectedObject: { Id: -1, IdLocatie: -1 },
        }
    },
    methods: {
        show_me: async function( id, idLocatie ) {
            this.showPopup        = true;
            var response          = await this.post("statii/get_lista_toate_locatiile" );
            this.Items            = response.Items;
            this.selectedObject.IdLocatie = idLocatie;
            this.selectedObject.Id        = id;
        },

        save: async function() {
            //verificam daca codul incepe cu B - desktop, kd - kitchen display , altfel e mobil
            this.$refs['my-form'].validate( async(valid) => {
                if (valid)
                {
                    var response = await this.post("statii/save_mutare_locatie", { object: this.selectedObject } );
                    if( !response.Eroare ){
                        this.showPopup = false;
                        this.$emit("save");
                    }else{
                        this.$message.error(response.MesajEroare);
                    }
                    
                }
            });
        }
    },
    mounted: function() {
        this.baseUrl = settings.BASE_URL;
    }
};
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>